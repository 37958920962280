<template>
  <div class="difficulties-range">
    <div
      class="difficulties-chart d-flex align-end justify-space-between flex-grow-1 mt-4"
    >
      <div
        v-for="(d, index) in difficulties"
        :key="d"
        class="chart-item d-flex flex-column align-center flex-grow-1"
      >
        <div class="chart-item-bar-wrap d-flex align-end">
          <div
            class="chart-item-bar"
            :class="barClasses(d, index)"
            :style="{
              height: `${barHeight(difficultiesCount[d])}%`
            }"
          >
            <span class="chart-item-bar-count">{{
              getFormattedCount(difficultiesCount[d])
            }}</span>
          </div>
        </div>
        <div
          :class="{ disabled: isBarItemDisabled(index) }"
          class="chart-item-value"
        >
          {{ d }}
        </div>
      </div>
    </div>
    <div v-if="difficultyLabels" class="difficulty-label">
      {{ difficultyLabels }}
    </div>
    <v-range-slider
      v-model="difficultyRange"
      min="0"
      :max="difficulties.length - 1"
      ticks
      thumb-label="always"
      class="difficulties-range-slider"
      hide-details
      @input="handleInput"
      @change="handleChange"
    >
      <template #thumb-label="props">
        {{ difficulties[props.value] }}
      </template>
    </v-range-slider>
  </div>
</template>

<script>
export default {
  name: 'DifficultyRange',
  props: {
    value: Array,
    difficulties: {
      type: Array,
      required: true
    },
    difficultiesCount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dRange: this.value
    };
  },
  computed: {
    maxDifficultyVal() {
      return Math.max(...Object.values(this.difficultiesCount));
    },
    difficultyLabels() {
      const range = this.difficultyRange;
      if (!range) {
        return '';
      }
      const min = this.$_getDifficultyNameByLevel(this.difficulties[range[0]]);
      const max = this.$_getDifficultyNameByLevel(this.difficulties[range[1]]);
      return min === max || !min || !max ? min || max : `${min} - ${max}`;
    },
    difficultyRange: {
      get() {
        if (this.dRange) {
          return this.dRange;
        }
        return this.difficulties ? [0, this.difficulties.length - 1] : [];
      },
      set(val) {
        this.dRange = val;
      }
    },
    barClasses() {
      return (d, index) => ({
        disabled: this.isBarItemDisabled(index),
        '-rounded': this.barHeight(this.difficultiesCount[d]) > 2,
        '-zero': this.difficultiesCount[d] === 0
      });
    }
  },
  watch: {
    value(newVal) {
      this.dRange = newVal;
    }
  },
  methods: {
    handleChange() {
      this.$emit('change', this.difficultyRange);
    },
    handleInput() {
      this.$emit('input', this.difficultyRange);
    },
    barHeight(val) {
      if (!val) {
        return 0;
      }
      return (val * 99) / this.maxDifficultyVal + 1;
    },
    isBarItemDisabled(index) {
      return this.difficultyRange[0] > index || this.difficultyRange[1] < index;
    },
    $_getDifficultyNameByLevel(d) {
      //copy of this function in the messages.js
      switch (true) {
        case 1 <= d && d <= 4:
          return this.$t('LibraryFilter.level.elementary');
        case 5 <= d && d <= 7:
          return this.$t('LibraryFilter.level.intermediate');
        case 8 <= d && d <= 9:
          return this.$t('LibraryFilter.level.difficult');
        case 10 <= d && d <= 12:
          return this.$t('LibraryFilter.level.challenging');
      }
    },
    getFormattedCount(count) {
      return count > 9999 ? '10k+' : count;
    }
  }
};
</script>

<style lang="less">
@import '~@/assets/styles/base.less';

.difficulties-range {
  display: flex;
  flex-direction: column;
  height: calc(100% - 28px);

  .difficulties-chart {
    font-size: 10px;
  }

  .chart-item {
    margin-left: 1px;
    align-self: stretch;

    &:first-child {
      margin-left: 0;
    }

    .chart-item-bar-wrap {
      width: 100%;
      min-height: 100px;
      height: 100%;
    }

    .chart-item-bar {
      position: relative;
      flex-grow: 1;
      background-color: var(--primary-color);
      min-height: 1px;

      &.disabled {
        background-color: #cbd5e1;

        .chart-item-bar-count {
          color: #cbd5e1;
        }
      }

      &.-rounded {
        border-radius: 1px 1px 0px 0px;
      }

      &:not(.-zero) {
        @media @wide {
          border-radius: 1px 1px 0px 0px;
        }
      }
    }

    .chart-item-bar-count {
      position: absolute;
      top: -1.6em;
      left: 0;
      right: 0;
      text-align: center;
      white-space: nowrap;
    }

    .chart-item-value {
      &.disabled {
        color: #cbd5e1;
      }
    }
  }
  .difficulty-label {
    text-align: center;
    font-size: 10px;
  }

  .difficulties-range-slider {
    flex-grow: 0;

    .v-slider__thumb {
      width: 24px;
      height: 24px;
      left: -12px;
      background-color: #fff !important;
      box-shadow: inset 0 0 0 1px var(--primary-color);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:before {
        left: -6px;
        top: -6px;
        transform: scale(0);
      }

      &::after {
        content: '< >';
        width: auto;
        height: auto;
        position: relative;
        top: -1px;
        left: auto;
        transform: scaleY(1.8);
        font-size: 8px;
        font-weight: 700;
      }
    }

    .v-slider__thumb-label-container {
      top: 60px;

      .v-slider__thumb-label {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #000;
        font-size: 14px;
      }
    }

    .v-slider__track-background {
      &.primary.lighten-3 {
        background-color: #cbd5e1 !important;
      }
    }
  }
}
</style>
