<template>
  <Popup
    :popup-visible="dialog"
    content-class="extra-filter-popup"
    hide-overlay
    scrollable
    :header-divider="true"
    :footer-divider="true"
    transition="scroll-y-reverse-transition"
    :header-title="'Filter'"
    :primary-label="'Show Results'"
    :secondary-label="'Reset Filters'"
    :primary-footer-handler="eventHandler"
    :secondary-footer-handler="resetFilters"
    @popupEvent="eventHandler"
  >
    <template #custom-content>
      <v-card-text>
        <v-expansion-panels v-model="panel" multiple flat>
          <v-expansion-panel v-if="showCategories">
            <v-expansion-panel-header>
              <slot name="categories-label"></slot>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <slot name="categories-checkboxes"></slot>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
            v-if="isDifficultyVisible"
            class="difficulty-block"
          >
            <v-expansion-panel-header>
              <slot name="difficulty-label"></slot>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <slot name="difficulty-selector"></slot>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <slot name="duration-label"></slot>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <slot name="duration-checkboxes"></slot>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <slot name="other-label"></slot>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <slot name="other-checkboxes"></slot>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="showGenres && !isOcean">
            <v-expansion-panel-header>
              <slot name="genres-label"></slot>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <slot name="genres-checkboxes"></slot>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </template>
  </Popup>
</template>

<script>
import Popup from '@/components/base/Popup/Popup.vue';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';

export default {
  name: 'ExtraFiltersPopup',
  components: {
    Popup
  },
  props: {
    isDifficultyVisible: {
      type: Boolean,
      default: true
    },
    showCategories: {
      type: Boolean,
      default: false
    },
    showGenres: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: true,
      panel: [0, 1, 2, 3, 4]
    };
  },
  computed: {
    isOcean() {
      return this.$store.getters['ContextStore/brand'] === BrandsEnum.OCEAN;
    }
  },
  mounted() {
    this.dialog = true;
  },
  methods: {
    resetFilters() {
      this.dialog = false;
      this.$emit('reset');
    },
    eventHandler() {
      this.dialog = false;
      this.$emit('close');
    }
  }
};
</script>

<style lang="less">
.extra-filter-popup {
  .v-card {
    .v-card__text {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
  }

  .v-expansion-panel-header {
    font-size: 1em;
  }

  .v-expansion-panel-header {
    font-weight: 700;
  }

  .v-slider__thumb-label {
    font-size: 1em;
  }

  .difficulty-block {
    font-weight: 500;
  }

  .difficulty-label {
    font-size: 10px;
  }
}
</style>
