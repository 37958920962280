<template>
  <div class="library-filter pb-4">
    <div class="library-filter-input-wrap">
      <div class="flex-grow-1">
        <BaseTextField
          v-model="filterText"
          rounded
          clearable
          hide-details
          prepend-inner-icon="$searchIcon"
          :placeholder="inputPlaceholder"
          clear-icon="$crossIcon"
          :class="[
            'library-filter-input-text',
            brand === OCEAN ? 'library-filter-input-ocean' : ''
          ]"
          @input="textInputHandler"
        >
          <template #prepend-inner>
            <span
              class="prepend-inner-text"
              :title="$t('Accessibility.filter.tooltip')"
              @click="toggleFilter"
              >{{ $t('LibraryFilter.prepend.text') }}</span
            >
          </template>
        </BaseTextField>
      </div>
      <div
        v-if="shouldShowKeyboardControl"
        class="flex-grow-0 virtual-keyboard-control buttons-block"
      >
        <BaseButton outlined @click="toggleVirtualKeyboard">
          <BaseSpriteIcon icon-name="ico-keyboard" />
        </BaseButton>
      </div>
      <div v-if="showLanguage && languagesWithoutDefault" class="flex-grow-0">
        <LanguagesSwitcher
          :hide="isSmallScreen && isMounted"
          :languages-list="languagesList"
          :current-lang="currentLanguage"
          @onLanguageChanged="onLanguageChanged"
        />
        <div v-show="false">
          <NuxtLink
            v-for="lang in languagesWithoutDefault"
            :key="lang"
            :to="{
              name: MANAGE_PUBLICATION_LANGUAGE,
              params: { pathMatch: lang }
            }"
          />
        </div>
      </div>
      <div v-if="showExtra" class="flex-grow-0 buttons-block">
        <BaseButton
          v-if="isSmallScreen && isMounted"
          outlined
          fab
          small
          :aria-label="$t('LibraryFilter.showFilters')"
          @click="toggleFilter"
        >
          <BaseSpriteIcon icon-name="ico-filter" />
        </BaseButton>
        <BaseButton
          v-else
          outlined
          rounded
          large
          text
          block
          class="black--text px-6"
          :class="{ expanded: isAllFilterVisible }"
          @click="toggleFilter"
        >
          {{
            isAllFilterVisible
              ? $t('LibraryFilter.hideFilters')
              : $t('LibraryFilter.showFilters')
          }}
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </BaseButton>
      </div>
    </div>
    <component
      :is="extraFilterComponent"
      v-if="showExtra && isAllFilterVisible"
      :is-difficulty-visible="showDifficulty"
      :show-categories="showCategories"
      :show-genres="showGenres"
      :brand="brand"
      @reset="resetFiltersHandler"
      @close="closeExtraFiltersHandler"
    >
      <template #categories-label>
        {{ $t('LibraryFilter.section.categories') }}
      </template>
      <template #categories-checkboxes>
        <LibraryFilterCheckbox
          v-for="category in categoriesView"
          :key="category"
          :value="categories[category]"
          :label="getLocalizedCategory(category)"
          :counter="categoryCount[category]"
          @change="changeCategory(category, $event)"
        />
      </template>
      <template #difficulty-label>
        {{ $t('LibraryFilter.section.level') }}
      </template>
      <template #difficulty-selector>
        <DifficultyRange
          v-model="difficultyRange"
          :difficulties="difficulties"
          :difficulties-count="difficultyCount"
          @change="triggerInput"
        />
      </template>

      <template #duration-label>
        {{ $t('LibraryFilter.section.duration') }}
      </template>
      <template #duration-checkboxes>
        <LibraryFilterCheckbox
          v-for="d in durations"
          :key="d.id"
          v-model="durationsModels[d.id]"
          :label="d.label"
          :counter="d.counter"
          @change="triggerInput"
        />
      </template>

      <template #other-label>
        {{ $t('LibraryFilter.section.other') }}
      </template>
      <template #other-checkboxes>
        <LibraryFilterCheckbox
          v-model="offlineBooksOnly"
          :label="$t('LibraryFilter.level.offline')"
          :counter="filterProps.offline"
          @change="triggerInput"
        />
        <LibraryFilterCheckbox
          v-model="booksWithAudioOnly"
          :label="$t('LibraryFilter.level.audio')"
          :counter="filterProps.audio"
          @change="triggerInput"
        />
      </template>
      <template v-if="showGenres && genresView.length" #genres-label>{{
        $t('LibraryFilter.section.genres')
      }}</template>
      <template v-if="showGenres && genresView.length" #genres-checkboxes>
        <LibraryFilterCheckbox
          v-for="g in genresView"
          :key="g"
          :value="genres[g]"
          :label="g"
          :counter="genresCount[g]"
          @change="changeGenre(g, $event)"
        />
      </template>
    </component>
    <v-row v-if="showExtra && chips.length" class="mt-4" no-gutters>
      <v-col class="chips-block">
        <div class="chips-content">
          <v-chip
            v-for="c in chips"
            :key="c.id"
            class="me-2 mt-4"
            :close="c.hasCloseButton"
            close-icon="$crossIcon"
            :outlined="c.hasCloseButton"
            v-on="c.handlers"
          >
            {{ c.label }}
            <span v-if="c.counter" class="ml-1 chip-value">{{
              c.counter
            }}</span>
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <SearchLoader
      v-if="isVirtualKeyboardVisible"
      class="virtual-keyboard-wrapper"
    >
      <sw-virtual-keyboard
        v-model="keyboardModel"
        element-selector=".library-filter-input-text input"
        :language="currentLanguage"
        @input="textInputHandler"
        @close="closeVirtualKeyboard"
      />
    </SearchLoader>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';

import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
import AppStateEnum from '@/enums/AppStateEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import FilterDurationRanges from '@/enums/FilterDurationRanges';

import publicationUtils from '@/services/utils/publicationUtils';

import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseSelect from '@/components/base/BaseSelect/BaseSelect.vue';
import LanguagesSwitcher from '@/components/base/LanguagesSwitcher/LanguagesSwitcher.vue';
import ExtraFilters from '@/components/views/LibraryFilter/ExtraFilters.vue';
import ExtraFiltersPopup from '@/components/views/LibraryFilter/ExtraFiltersPopup.vue';
import DifficultyRange from '@/components/views/LibraryFilter/DifficultyRange.vue';
import LibraryFilterCheckbox from '@/components/views/LibraryFilter/LibraryFilterCheckbox.vue';
import SearchLoader from '@/components/SearchLoader.vue';

class Chip {
  setId(id) {
    this.id = id;
    return this;
  }
  setLabel(label) {
    this.label = label;
    return this;
  }
  setCounter(counter) {
    this.counter = counter;
    return this;
  }
  setHasCloseButton(val) {
    this.hasCloseButton = val;
    return this;
  }
  setClickHandler(handler) {
    this.clickHandler = handler;
    return this;
  }
  setCloseHandler(handler) {
    this.closeHandler = handler;
    return this;
  }
  build() {
    const handlers = {};
    if (this.clickHandler) {
      handlers.click = this.clickHandler;
    }
    if (this.closeHandler) {
      handlers['click:close'] = this.closeHandler;
    }
    return {
      id: this.id || this.label,
      label: this.label,
      counter: this.counter,
      hasCloseButton: this.hasCloseButton ?? true,
      handlers
    };
  }
}

class Duration {
  setId(id) {
    this.id = id;
    return this;
  }
  setLabel(label) {
    this.label = label;
    return this;
  }
  setCounter(counter) {
    this.counter = counter;
    return this;
  }
  setMin(min) {
    this.min = min;
    return this;
  }
  setMax(max) {
    this.max = max;
    return this;
  }
  build() {
    return {
      id: this.id,
      label: this.label,
      min: this.min,
      max: this.max,
      counter: this.counter
    };
  }
}

const collator = new Intl.Collator(undefined, {
  usage: 'sort',
  numeric: true
});

export default {
  name: 'LibraryFilter',
  components: {
    SearchLoader,
    BaseButton,
    BaseTextField,
    BaseSpriteIcon,
    BaseSelect,
    LanguagesSwitcher,
    ExtraFilters,
    ExtraFiltersPopup,
    DifficultyRange,
    LibraryFilterCheckbox
  },
  props: {
    searcher: Object,
    showCategories: {
      type: Boolean,
      default: false
    },
    showGenres: {
      type: Boolean,
      default: true
    },
    showExtra: {
      type: Boolean,
      default: false
    },
    showLanguage: {
      type: Boolean,
      default: false
    },
    state: {
      type: String
    },
    placeholder: String
  },
  data() {
    const savedCategoriesFilter = this.searcher?.categories?.reduce((r, c) => {
      r[c] = true;
      return r;
    }, {});
    return {
      MANAGE_PUBLICATION_LANGUAGE: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
      KEYBOARD_FOR_LANGS: ['ar', 'fa'],
      OCEAN: BrandsEnum.OCEAN,
      isMounted: false,
      filterText: this.searcher?.term || '',
      categories: savedCategoriesFilter || {},
      durationRanges: FilterDurationRanges,
      genres: this.$_getActiveGenresMap(this.searcher?.genres) || {},
      languagesList: this.$store.getters['ContextStore/getLibraryLanguages'],
      durationsModels: this.$_getDurationModelFromSearcher(
        this.searcher?.durations
      ),
      offlineBooksOnly: this.searcher?.offline || false,
      booksWithAudioOnly: this.searcher?.audio || false,
      isAllFilterVisible: false,
      isVirtualKeyboardVisible: false,
      debouncedInput: debounce(this.triggerInput.bind(this), 500),
      difficultyRangeCache: null,
      currentRouteName: this.$route.params.name
    };
  },

  computed: {
    ...mapGetters('LibraryStore', ['getFilterProps']),
    ...mapGetters('ContextStore', {
      appState: 'appState',
      showDifficulty: 'showDifficulty',
      currentLanguage: 'currentLanguageGetter',
      brand: 'brand',
      isDevice: 'isDevice',
      isDeviceBrowser: 'isDeviceBrowser'
    }),
    isMobile() {
      return this.isDevice || this.isDeviceBrowser;
    },
    shouldShowKeyboardControl() {
      const restrictedAppStates =
        this.appState === AppStateEnum.MANAGE_COMPILATION;
      return (
        this.isMounted &&
        !this.isMobile &&
        !restrictedAppStates &&
        this.KEYBOARD_FOR_LANGS.includes(this.currentLanguage)
      );
    },
    inputPlaceholder() {
      return this.placeholder ?? this.$t('LibraryFilter.placeholder');
    },
    filterProps() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterProps;
    },
    languagesWithoutDefault() {
      const languagesList = this.$store.getters[
        'ContextStore/getLibraryLanguages'
      ];
      const languages = languagesList.filter(lang => lang !== 'en');
      return languages.length ? languages : null;
    },
    isSmallScreen() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    },
    extraFilterComponent() {
      return this.isSmallScreen ? ExtraFiltersPopup : ExtraFilters;
    },
    difficulties() {
      return Object.keys(this.filterProps.difficulties)
        .map(d => parseInt(d))
        .sort((a, b) => a - b);
    },
    difficultyCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutDifficulty.difficulties;
    },
    difficultyRange: {
      get() {
        if (this.difficultyRangeCache) {
          return this.difficultyRangeCache;
        }
        const difficulty = this.searcher?.difficultyRange;
        if (difficulty) {
          const min = this.difficulties.indexOf(difficulty[0]);
          const max = this.difficulties.indexOf(difficulty[1]);
          return min > -1 && max > -1 ? [min, max] : null;
        }
        return null;
      },
      set(val) {
        this.difficultyRangeCache = val;
      }
    },
    categoryCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutCategories.categories;
    },
    durationCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutDurations.durations;
    },
    genresView() {
      const genresKeys = Object.keys(this.filterProps.genres);
      return genresKeys.length ? genresKeys.sort(collator.compare) : [];
    },
    genresCount() {
      return this.getFilterProps(this.searcher, this.currentRouteName)
        .filterPropsWithoutGenres.genres;
    },
    chips() {
      const chips = [];
      if (!this.showExtra) {
        return chips;
      }
      if (this.showCategories) {
        const categories = Object.keys(this.categories || {})
          .reduce((result, val) => {
            if (this.categories[val] && this.categoryCount[val]) {
              result.push(this.$_getCategoryChip(val));
            }
            return result;
          }, [])
          .sort((a, b) =>
            publicationUtils.sortByCategory(a.id, b.id, this.brand)
          );
        chips.push(...categories);
      }

      if (this.showGenres) {
        const genresChips = Object.entries(this.genres)
          .reduce((result, [key, val]) => {
            if (val) {
              result.push(this.$_getGenreChip(key));
            }
            return result;
          }, [])
          .sort((a, b) => collator.compare(a.id, b.id));
        chips.push(...genresChips);
      }

      const rangeChips = this.$_getDifficultyChip();
      if (rangeChips) {
        chips.push(rangeChips);
      }

      const durationChips = Object.keys(this.durationsModels).reduce(
        (result, key) => {
          if (this.durationsModels[key]) {
            result.push(this.$_getDurationChip(key));
          }
          return result;
        },
        []
      );
      chips.push(...durationChips);

      if (this.booksWithAudioOnly) {
        chips.push(this.$_getAudioChip());
      }
      if (this.offlineBooksOnly) {
        chips.push(this.$_getOfflineChip());
      }
      if (chips.length) {
        chips.unshift(this.$_getResetChip());
      }
      return chips;
    },
    categoriesView() {
      const categoriesNames = Object.keys(this.filterProps.categories);
      if (!categoriesNames.length) {
        return [];
      }
      const sortedCategories = categoriesNames.sort((a, b) =>
        publicationUtils.sortByCategory(a, b, this.brand)
      );
      return sortedCategories;
    },
    durations() {
      const durationsMap = this.durationRanges.map(([key, min, max]) => {
        const duration = new Duration();
        duration
          .setId(key)
          .setMin(min)
          .setMax(max)
          .setLabel(this.$_getDurationLabel(key))
          .setCounter(this.durationCount[key]);
        return duration.build();
      });
      return durationsMap;
    },
    keyboardModel: {
      get() {
        return this.filterText || '';
      },
      set(value) {
        this.filterText = value;
      }
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    getLocalizedCategory(name) {
      return publicationUtils.getCategoryLocalizationKey(
        name,
        this.$t.bind(this)
      );
    },
    $_getDurationModelFromSearcher(durations) {
      if (!durations) {
        return {};
      }
      const models = {};
      durations.forEach(([min, max]) => {
        const range = FilterDurationRanges.find(
          ([, dMin, dMax]) => dMin === min && dMax === max
        );
        if (range) {
          models[range[0]] = true;
        }
      });
      return models;
    },
    $_getDurationLabel(name) {
      return this.$t(`LibraryFilter.duration.${name}`);
    },
    $_getActiveGenresMap(genres) {
      if (!genres?.length) {
        return;
      }
      const genresObj = {};
      genres.forEach(genre => {
        genresObj[genre] = true;
      });
      return genresObj;
    },
    resetFiltersHandler() {
      this.closeExtraFiltersHandler();
      this.$_resetFilters();
    },
    closeExtraFiltersHandler() {
      this.isAllFilterVisible = false;
    },
    toggleFilter() {
      this.isAllFilterVisible = !this.isAllFilterVisible;
    },
    toggleVirtualKeyboard() {
      this.isVirtualKeyboardVisible = !this.isVirtualKeyboardVisible;
    },
    closeVirtualKeyboard() {
      this.isVirtualKeyboardVisible = false;
    },
    onLanguageChanged({ language }) {
      this.$store.commit('ContextStore/setCurrentLanguage', language);
      if (
        language === 'en' &&
        AppStateEnum.MANAGE_PUBLICATION !== this.$route.name
      ) {
        this.$router.push({
          name: AppStateEnum.MANAGE_PUBLICATION
        });
      } else {
        this.$router.push({
          name: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
          params: { pathMatch: language }
        });
      }
      this.resetFiltersHandler();
      this.triggerInput();
    },

    // --- chips ---
    $_getResetChip() {
      const c = new Chip();
      c.setId('reset')
        .setLabel(this.$t('LibraryFilter.level.reset'))
        .setHasCloseButton(false)
        .setClickHandler(this.$_resetFilters.bind(this));
      return c.build();
    },
    $_getCategoryChip(val) {
      const c = new Chip();
      c.setId(val)
        .setLabel(this.getLocalizedCategory(val))
        .setCounter(this.filterProps.categories[val])
        .setCloseHandler(this.$_closeChipCategory.bind(this, val));
      return c.build();
    },
    $_getGenreChip(val) {
      const c = new Chip();
      c.setId(val)
        .setLabel(val)
        .setCounter(this.filterProps.genres[val])
        .setCloseHandler(this.$_closeChipGenre.bind(this, val));
      return c.build();
    },
    $_getDifficultyChip() {
      const range = this.difficultyRange;
      const rangeChanged =
        range && (range[0] !== 0 || range[1] !== this.difficulties.length - 1);
      if (!rangeChanged) {
        return;
      }
      let count = 0;
      for (let i = range[0]; i <= range[1]; i++) {
        count += this.filterProps.difficulties[this.difficulties[i]];
      }
      const levels =
        range[0] === range[1]
          ? this.difficulties[range[0]]
          : `${this.difficulties[range[0]]} - ${this.difficulties[range[1]]}`;
      const c = new Chip();
      c.setId('d-range')
        .setLabel(`${this.$t('LibraryFilter.section.level')} ${levels}`)
        .setCounter(count)
        .setCloseHandler(this.$_closeRangeChip.bind(this));
      return c.build();
    },
    $_getDurationChip(val) {
      const c = new Chip();
      c.setId(val)
        .setLabel(this.$_getDurationLabel(val))
        .setCounter(this.durationCount[val])
        .setCloseHandler(this.$_closeChipDuration.bind(this, val));
      return c.build();
    },
    $_getAudioChip() {
      const c = new Chip();
      c.setId('with-audio')
        .setLabel(this.$t('LibraryFilter.level.audio'))
        .setCounter(this.filterProps.audio)
        .setCloseHandler(this.$_closeWithAudioChip.bind(this));
      return c.build();
    },
    $_getOfflineChip() {
      const c = new Chip();
      c.setId('offline')
        .setLabel(this.$t('LibraryFilter.level.offline'))
        .setCounter(this.filterProps.offline)
        .setCloseHandler(this.$_closeOfflineChip.bind(this));
      return c.build();
    },
    $_resetFilters() {
      this.filterText = '';
      if (this.showCategories) {
        this.categories = {};
      }
      if (this.showGenres) {
        this.genres = {};
      }
      this.durationsModels = {};
      this.difficultyRange = [0, this.difficulties.length - 1];
      this.booksWithAudioOnly = false;
      this.offlineBooksOnly = false;
      this.triggerInput();
    },
    $_closeChipCategory(id) {
      this.categories = { ...this.categories, [id]: false };
      this.triggerInput();
    },
    $_closeChipGenre(name) {
      this.genres = { ...this.genres, [name]: false };
      this.triggerInput();
    },
    $_closeChipDuration(id) {
      this.durationsModels = { ...this.durationsModels, [id]: false };
      this.triggerInput();
    },
    $_closeRangeChip() {
      this.difficultyRange = [0, this.difficulties.length - 1];
      this.triggerInput();
    },
    $_closeWithAudioChip() {
      this.booksWithAudioOnly = false;
      this.triggerInput();
    },
    $_closeOfflineChip() {
      this.offlineBooksOnly = false;
      this.triggerInput();
    },
    // --- chips ---

    textInputHandler(val) {
      if (val) {
        return this.debouncedInput();
      }
      this.triggerInput();
    },
    changeCategory(category, val) {
      this.categories = { ...this.categories, [category]: val };
      this.triggerInput();
    },
    changeGenre(genre, val) {
      this.genres = { ...this.genres, [genre]: val };
      this.triggerInput();
    },
    triggerInput() {
      const _getSelectedProps = obj => {
        return Object.entries(obj).reduce((result, [key, val]) => {
          if (val) {
            result.push(key);
          }
          return result;
        }, []);
      };
      const categories = _getSelectedProps(this.categories);
      const genres = _getSelectedProps(this.genres);
      const recentBookIds = this.categories.recent
        ? this.$store.getters['RecentBookStore/getRecentBookIds']
        : null;
      const newBookIds = this.categories.new
        ? this.$store.getters['LibraryStore/getNewBookIds']
        : null;
      const currentLanguage = this.$store.getters[
        'ContextStore/currentLanguageGetter'
      ];
      const durations = Object.keys(this.durationsModels).reduce(
        (result, key) => {
          if (this.durationsModels[key]) {
            const duration = this.durations.find(d => d.id === key);
            result.push([duration.min, duration.max]);
          }
          return result;
        },
        []
      );
      const difficultRange =
        this.difficultyRange &&
        (this.difficultyRange[0] !== 0 ||
          this.difficultyRange[1] !== this.difficulties.length - 1)
          ? [
              this.difficulties[this.difficultyRange[0]],
              this.difficulties[this.difficultyRange[1]]
            ]
          : null;
      const builder = SearchPublicationsFactory.getSearcherBuilder();
      builder
        .setState(
          this.state || this.searcher?.state || ManagePublicationsStates.LIBRARY
        )
        .setLanguage(currentLanguage)
        .setCategories(categories)
        .setAudio(this.booksWithAudioOnly)
        .setOffline(this.offlineBooksOnly)
        .setDifficultyRange(difficultRange)
        .setDurations(durations)
        .setAllowBookList(recentBookIds || newBookIds)
        .setCollectionIncluded(!!(this.chips.length || this.filterText))
        .setFilter(this.filterText)
        .setGenres(genres)
        .setAuthorHash(this.searcher?.authorHash)
        .setCollectionId(this.searcher?.collectionId);
      const searcher = builder.build();
      this.$emit('change', searcher);
    }
  }
};
</script>

<style lang="less" src="./LibraryFilter.less"></style>
