<template>
  <v-checkbox
    v-model="input"
    :value="value"
    :disabled="disabled"
    hide-details
    color="primary"
    class="library-filter-checkbox"
    @change="triggerChange"
  >
    <template #label
      ><span class="checkbox-label">{{ label }}</span>
      <span class="checkbox-counter ms-1"> ({{ counter }})</span></template
    >
  </v-checkbox>
</template>

<script>
export default {
  name: 'LibraryFilterCheckbox',
  props: {
    value: Boolean,
    label: String,
    counter: [String, Number],
    disabled: Boolean
  },
  data() {
    return {
      input: this.value
    };
  },
  methods: {
    triggerChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="less">
.library-filter-checkbox {
  .checkbox-label {
    color: #0f172a;
  }
  .checkbox-counter {
    color: #94a3b8;
  }

  .v-icon {
    svg {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      fill: currentColor;
    }
  }
}
</style>
