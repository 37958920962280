<template>
  <v-row
    class="extra-filters-block my-6"
    :class="[showCategories ? '' : 'mb-6']"
  >
    <v-col
      v-if="showCategories"
      class="pe-lg-8 pe-xl-8 col-12"
      :class="[
        isDifficultyVisible ? 'col-lg-4 col-xl-4' : 'col-lg-6 col-xl-6',
        categoryContent ? 'collapsed' : 'expanded'
      ]"
    >
      <h3 class="mb-0">
        <slot name="categories-label"></slot>
        <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        <div
          class="content-collapse-handler"
          @click="categoryContent = !categoryContent"
        ></div>
      </h3>
      <div class="categories-list">
        <slot name="categories-checkboxes"></slot>
      </div>
    </v-col>
    <v-col
      v-if="isDifficultyVisible"
      class="px-lg-8 px-xl-8 col-12 col-lg-4 col-xl-4"
      :class="[
        difficultyContent ? 'collapsed' : 'expanded',
        showCategories ? 'delimiter col-xl-4' : 'col-xl-6'
      ]"
    >
      <h3 class="mb-0">
        <slot name="difficulty-label"></slot>
        <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        <div
          class="content-collapse-handler"
          @click="difficultyContent = !difficultyContent"
        ></div>
      </h3>
      <slot name="difficulty-selector"></slot>
    </v-col>
    <v-col
      class="ps-lg-8 ps-xl-8 col-12"
      :class="[
        isDifficultyVisible ? 'col-lg-4 col-xl-4' : 'col-lg-6 col-xl-6',
        showCategories ? 'col-xl-4' : 'col-xl-6',
        !showCategories && isOcean
          ? 'd-lg-flex justify-lg-space-between'
          : 'delimiter'
      ]"
    >
      <div :class="durationContent ? 'collapsed' : 'expanded'">
        <h3 class="mb-0">
          <slot name="duration-label"></slot>
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
          <div
            class="content-collapse-handler"
            @click="durationContent = !durationContent"
          ></div>
        </h3>
        <div
          class="duration-list"
          :class="[!showCategories && isOcean ? 'duration-list-ocean' : '']"
        >
          <slot name="duration-checkboxes"></slot>
        </div>
      </div>

      <div :class="otherContent ? 'collapsed' : 'expanded'">
        <h3
          class="pt-8 mb-0"
          :class="[!showCategories && isOcean ? 'pt-lg-0' : '']"
        >
          <slot name="other-label"></slot>
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
          <div
            class="content-collapse-handler"
            @click="otherContent = !otherContent"
          ></div>
        </h3>
        <div class="other-list-block">
          <slot name="other-checkboxes"></slot>
        </div>
      </div>
    </v-col>
    <v-col
      v-if="showGenres && !isOcean"
      class="col-12"
      :class="[genresContent ? 'collapsed' : 'expanded']"
    >
      <h3 class="mb-0">
        <slot name="genres-label"></slot>
        <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        <div
          class="content-collapse-handler"
          @click="genresContent = !genresContent"
        ></div>
      </h3>
      <div class="genres-list">
        <slot name="genres-checkboxes"></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BrandsEnum from '@shared/enums/BrandsEnum.mjs';
export default {
  name: 'ExtraFilters',
  components: {
    BaseSpriteIcon
  },
  props: {
    isDifficultyVisible: {
      type: Boolean,
      default: true
    },
    showCategories: {
      type: Boolean,
      default: false
    },
    showGenres: {
      type: Boolean,
      default: false
    },
    brand: {
      type: String
    }
  },
  data() {
    return {
      categoryContent: false,
      difficultyContent: false,
      genresContent: false,
      durationContent: false,
      otherContent: false
    };
  },
  computed: {
    isOcean() {
      return this.brand === BrandsEnum.OCEAN;
    }
  }
};
</script>

<style lang="less">
@import '~assets/styles/base.less';

.extra-filters-block {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  .v-label {
    font-size: 14px;
  }

  .collapsed {
    .categories-list,
    .difficulties-range,
    .duration-list,
    .other-list-block,
    .genres-list {
      @media @normal {
        display: none;
      }
    }

    h3 {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  .delimiter {
    @media @wide {
      box-shadow: inset 1px 0 0 #cbd5e1;
    }
  }

  .categories-list,
  .duration-list,
  .other-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media @wide {
      grid-template-columns: 1fr 1fr;
    }
  }

  .duration-list-ocean {
    @media @wide {
      grid-template-columns: 2fr 1fr;
    }
  }

  .genres-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 20px;
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    svg {
      transform: rotate(180deg);

      @media @wide {
        display: none;
      }
    }

    .content-collapse-handler {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      @media @wide {
        display: none;
      }
    }
  }
}
</style>
