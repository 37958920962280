var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"extra-filters-block my-6",class:[_vm.showCategories ? '' : 'mb-6']},[(_vm.showCategories)?_c('v-col',{staticClass:"pe-lg-8 pe-xl-8 col-12",class:[
      _vm.isDifficultyVisible ? 'col-lg-4 col-xl-4' : 'col-lg-6 col-xl-6',
      _vm.categoryContent ? 'collapsed' : 'expanded'
    ]},[_c('h3',{staticClass:"mb-0"},[_vm._t("categories-label"),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"icon-name":"ico-arrow-chevron"}}),_vm._v(" "),_c('div',{staticClass:"content-collapse-handler",on:{"click":function($event){_vm.categoryContent = !_vm.categoryContent}}})],2),_vm._v(" "),_c('div',{staticClass:"categories-list"},[_vm._t("categories-checkboxes")],2)]):_vm._e(),_vm._v(" "),(_vm.isDifficultyVisible)?_c('v-col',{staticClass:"px-lg-8 px-xl-8 col-12 col-lg-4 col-xl-4",class:[
      _vm.difficultyContent ? 'collapsed' : 'expanded',
      _vm.showCategories ? 'delimiter col-xl-4' : 'col-xl-6'
    ]},[_c('h3',{staticClass:"mb-0"},[_vm._t("difficulty-label"),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"icon-name":"ico-arrow-chevron"}}),_vm._v(" "),_c('div',{staticClass:"content-collapse-handler",on:{"click":function($event){_vm.difficultyContent = !_vm.difficultyContent}}})],2),_vm._v(" "),_vm._t("difficulty-selector")],2):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"ps-lg-8 ps-xl-8 col-12",class:[
      _vm.isDifficultyVisible ? 'col-lg-4 col-xl-4' : 'col-lg-6 col-xl-6',
      _vm.showCategories ? 'col-xl-4' : 'col-xl-6',
      !_vm.showCategories && _vm.isOcean
        ? 'd-lg-flex justify-lg-space-between'
        : 'delimiter'
    ]},[_c('div',{class:_vm.durationContent ? 'collapsed' : 'expanded'},[_c('h3',{staticClass:"mb-0"},[_vm._t("duration-label"),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"icon-name":"ico-arrow-chevron"}}),_vm._v(" "),_c('div',{staticClass:"content-collapse-handler",on:{"click":function($event){_vm.durationContent = !_vm.durationContent}}})],2),_vm._v(" "),_c('div',{staticClass:"duration-list",class:[!_vm.showCategories && _vm.isOcean ? 'duration-list-ocean' : '']},[_vm._t("duration-checkboxes")],2)]),_vm._v(" "),_c('div',{class:_vm.otherContent ? 'collapsed' : 'expanded'},[_c('h3',{staticClass:"pt-8 mb-0",class:[!_vm.showCategories && _vm.isOcean ? 'pt-lg-0' : '']},[_vm._t("other-label"),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"icon-name":"ico-arrow-chevron"}}),_vm._v(" "),_c('div',{staticClass:"content-collapse-handler",on:{"click":function($event){_vm.otherContent = !_vm.otherContent}}})],2),_vm._v(" "),_c('div',{staticClass:"other-list-block"},[_vm._t("other-checkboxes")],2)])]),_vm._v(" "),(_vm.showGenres && !_vm.isOcean)?_c('v-col',{staticClass:"col-12",class:[_vm.genresContent ? 'collapsed' : 'expanded']},[_c('h3',{staticClass:"mb-0"},[_vm._t("genres-label"),_vm._v(" "),_c('BaseSpriteIcon',{attrs:{"icon-name":"ico-arrow-chevron"}}),_vm._v(" "),_c('div',{staticClass:"content-collapse-handler",on:{"click":function($event){_vm.genresContent = !_vm.genresContent}}})],2),_vm._v(" "),_c('div',{staticClass:"genres-list"},[_vm._t("genres-checkboxes")],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }